export const washes = {

    state: () => ({
        washes: [],
        washesAddress: [],
        washesId: null,
        boxes: [],
        selectedBox: null,
        selectedWash: null
    }),
    
    actions: {
        async loadAllWashes({commit}) {
            const res = await fetch(
                process.env.VUE_APP_API_DOMAIN+'/api/washes'
                )
            const washes = await res.json()
            commit('setWashes', washes)
            commit('setWashesAddress', washes)
        },

        async loadWashPosts({commit}, washId) {
            if(!washId) { 
                return 
            }

            const res = await fetch(
                process.env.VUE_APP_API_DOMAIN+'/api/washes/'+washId+'/boxes'
                )
            const boxes = await res.json()

            commit('setWashBoxes', boxes)
        },

        selectedWash({commit, dispatch, state}, idWahs) {
            
            if(!state.washes) {
                dispatch('loadAllWashes')
                .then(()=>{
                    commit('setSelectedWash', idWahs)
                })
                return
            }

            commit('setSelectedWash', idWahs)
        },

        selectedBox({commit}, idWahs) {
            commit('setSelectedBox', idWahs)
        }
    },
    mutations: {
        setWashes(state, washes){
            if(!washes) {
                state.washes = []
            }
            
            state.washes = washes.data
        },
        setWashesAddress(state, washes) {
            if(washes){
                state.washesAddress = []

                const activWashes = washes.data.filter( wash => wash.washGroupId)

                activWashes.every(wash => {
                    let address = {
                        value: wash.id,
                        name: `м. ${wash.city}, ${wash.address}`,
                        region: wash.region,
                        city: wash.city
                    }
                    return state.washesAddress.push(address);
                })
            }

        },
        setWashBoxes(state, boxes) {
            if(boxes){
                if(state.boxes.length) {
                    state.boxes= []
                }
                let count = 1;
                boxes.data.every(box => {
                    let boxes = {
                        value: box.id,
                        name: box.type != 'vacuum' ? `${box.number} пост` : `${count++} порохотяг`,
                        type: box.type,
                        count: count
                    }
                    return state.boxes.push(boxes);
                })
            }
        },
        setSelectedWash(state, washId) {
           
            let wash = state.washes.filter(item => item.id == washId)
            state.selectedWash = wash[0]

        },

        setSelectedBox(state, boxId) {
            let box = state.boxes.filter(item => item.value == boxId)
            state.selectedBox = box[0]
        }
    },

    getters: {
        getWashes( state ){
            return state.washes
        },
        getWashesAddress( state ){
            return state.washesAddress
        },
        getWashBoxes( state ) {
            return state.boxes
        },
        getSelectedWash( state ) {
            return state.selectedWash
        },
        getSelectedBox( state ) {
            return state.selectedBox
        }
    },
}